import React from "react";

const CarkerLogoBW = () => (
  <svg width="133" height="47" viewBox="0 0 133 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M124.927 32.5148H124.184V32.1226H126.098V32.5148H125.351V34.5365H124.926V32.5148H124.927Z" fill="white"/>
      <path
        d="M128.601 34.5354L128.598 32.8132H128.591L127.883 34.5013H127.551L126.839 32.8174H126.832V34.5365H126.431V32.1226H126.956L127.713 33.9184H127.72L128.487 32.1226H129.002V34.5365H128.601V34.5354Z"
        fill="white"/>
      <path
        d="M110.715 33.3203C109.804 33.6677 108.737 33.9661 107.514 34.2198C106.29 34.4724 104.862 34.5981 103.231 34.5981C101.191 34.5981 99.4416 34.3221 97.9827 33.7701C96.5239 33.218 95.3312 32.4613 94.4056 31.4989C93.4801 30.5376 92.7973 29.3866 92.3584 28.0448C91.9184 26.7041 91.699 25.2461 91.699 23.6678C91.699 21.775 91.981 20.0176 92.5461 18.3923C93.1112 16.7681 93.9423 15.357 95.0407 14.158C96.1391 12.9601 97.495 12.0212 99.1118 11.3434C100.728 10.6656 102.602 10.3267 104.736 10.3267C106.336 10.3267 107.741 10.5473 108.948 10.9896C110.156 11.4318 111.167 12.0542 111.984 12.8589C112.799 13.6635 113.411 14.6099 113.819 15.6981C114.226 16.7862 114.431 17.9777 114.431 19.2705C114.431 19.8385 114.399 20.3906 114.336 20.9267C114.273 21.4638 114.195 21.9679 114.101 22.4411C114.006 22.9143 113.92 23.3246 113.842 23.671C113.764 24.0184 113.709 24.271 113.678 24.4277H98.6634V24.7591C98.6634 26.0838 99.1882 27.0942 100.24 27.7869C101.291 28.4807 102.726 28.8282 104.546 28.8282C106.084 28.8282 107.464 28.6789 108.689 28.3784C109.912 28.0789 110.948 27.756 111.795 27.4086L110.712 33.3224L110.715 33.3203ZM104.313 15.5776C102.964 15.5776 101.858 15.9645 100.995 16.7372C100.132 17.5109 99.5433 18.6065 99.2295 20.025H107.702C107.764 19.8353 107.811 19.6467 107.843 19.457C107.873 19.2673 107.889 19.0477 107.889 18.7941C107.889 17.8797 107.599 17.1144 107.019 16.4995C106.438 15.8846 105.537 15.5766 104.312 15.5766L104.313 15.5776Z"
        fill="white"/>
      <path
        d="M25.3492 25.3801C24.4958 25.7808 23.4833 26.1965 22.3128 26.6249C20.7808 27.1866 18.9287 27.4669 16.7585 27.4669C15.5446 27.4669 14.4038 27.2985 13.3351 26.9617C12.2654 26.6249 11.3313 26.0952 10.5341 25.3737C9.73575 24.6522 9.10494 23.7303 8.64269 22.606C8.17939 21.4837 7.94827 20.1355 7.94827 18.5636C7.94827 16.7997 8.18787 15.2107 8.66602 13.7986C9.14522 12.3875 9.85449 11.1758 10.797 10.1644C11.7385 9.15402 12.9036 8.37602 14.2925 7.83035C15.6813 7.28469 17.2684 7.01185 19.0559 7.01185C19.9815 7.01185 20.8911 7.08432 21.7848 7.2282C22.6786 7.37208 23.5161 7.55752 24.2986 7.78133C25.0799 8.0062 25.7903 8.24706 26.4296 8.50284C27.0678 8.75969 27.7431 8.96858 28.1587 9.16041L29.3705 2.168C28.8277 1.94419 28.0251 1.64365 27.3869 1.40385C26.7476 1.16299 25.9981 0.939185 25.1361 0.730298C24.2742 0.522475 23.2765 0.353022 22.1442 0.225131C21.0109 0.0972406 19.6782 0.0322266 18.1463 0.0322266C15.4968 0.0322266 13.0626 0.464923 10.8447 1.33138C8.62573 2.19784 6.7195 3.43305 5.12285 5.03701C3.5262 6.64204 2.27411 8.60728 1.36447 10.9328C0.454822 13.2593 0 15.8821 0 18.8023C0 21.3377 0.375307 23.5907 1.12486 25.5645C1.87442 27.5383 2.93567 29.1987 4.30862 30.5458C5.68051 31.893 7.31639 32.9129 9.21626 33.6024C11.1151 34.292 13.2142 34.6373 15.5117 34.6373C17.331 34.6373 19.0708 34.4444 20.73 34.0596C21.9545 33.7751 23.0486 33.4479 24.0123 33.0759L25.346 25.378L25.3492 25.3801Z"
        fill="white"/>
      <path
        d="M81.5391 18.5921H81.5423L97.0837 0H87.8822L76.1565 14.6403C75.6731 14.3578 75.1801 14.0893 74.6807 13.8324L77.0969 0H69.4656L63.4204 34.62H71.0517L73.452 20.9346C72.9293 20.6074 76.1141 22.6899 77.9164 24.5017C80.2096 26.8069 82.0088 29.4883 83.2651 32.4735C83.5588 33.1716 83.8185 33.8814 84.0465 34.5986H91.0681C89.5351 28.3277 86.1552 22.788 81.5412 18.5921H81.5391ZM84.4334 34.0594C84.2278 33.4455 84.0019 32.8433 83.7581 32.2636C82.4753 29.2145 80.6369 26.4744 78.2949 24.1201C75.953 21.7659 73.2272 19.9179 70.194 18.6283C69.1476 18.1828 68.0619 17.8066 66.9625 17.5071L67.9421 11.8565C73.3099 13.2207 78.2271 16.013 82.1911 19.9541C86.1244 23.8644 88.9446 28.7295 90.3737 34.0594H84.4345H84.4334Z"
        fill="white"/>
      <path
        d="M46.6706 13.4388C46.3822 13.0103 46.0397 12.6192 45.6401 12.2696C44.1971 11.0089 41.9707 10.3779 38.9598 10.3779C37.3599 10.3779 35.8386 10.5122 34.3967 10.7797C32.9538 11.0483 31.5713 11.3979 30.7878 11.6504L29.7064 17.5142C30.5535 17.2627 31.8724 16.9121 33.1266 16.6435C34.3808 16.376 35.7442 16.2417 37.2189 16.2417C38.5664 16.2417 39.5312 16.4549 40.1122 16.8801C40.2787 17.0027 40.4207 17.1391 40.5405 17.2893C39.6139 18.0173 38.3661 19.1629 37.6207 19.9388C37.3515 19.9335 37.0779 19.9303 36.797 19.9303C34.9151 19.9303 33.3238 20.1125 32.0219 20.4738C30.72 20.8362 29.6704 21.3488 28.8699 22.0106C28.0705 22.6725 27.4895 23.4611 27.1291 24.3755C26.7686 25.29 26.5884 26.3141 26.5884 27.4492C26.5884 29.7821 27.3485 31.563 28.8699 32.7929C29.1509 33.0199 29.4626 33.2256 29.8039 33.411C31.3105 34.2284 33.4065 34.6366 36.0909 34.6366C36.2277 34.6366 36.3634 34.6345 36.4991 34.6324C36.6531 34.1464 36.6531 34.1464 36.4991 34.6324C38.2251 34.6121 39.8387 34.465 41.3367 34.1869C42.9514 33.8874 44.3074 33.5954 45.4057 33.3119L47.0522 23.5709C47.2325 22.5073 47.5145 20.6838 47.5145 20.6838C47.542 20.4994 47.5685 20.3172 47.5929 20.1424C47.7339 19.1491 47.805 18.3061 47.805 17.6123C47.805 15.9486 47.4254 14.5578 46.6716 13.4377L46.6706 13.4388ZM47.0607 20.0667C47.0469 20.1605 47.0331 20.2564 47.0194 20.3534C45.2955 21.4106 43.6946 22.6767 42.2602 24.1187C40.8056 25.5809 39.6542 27.0357 38.6385 28.6929L38.5622 28.8155C37.8922 29.91 37.2974 31.0695 36.7959 32.2611C36.5468 32.8536 36.3156 33.4686 36.1079 34.0942C36.1025 34.0942 36.0962 34.0942 36.0909 34.0942C33.7351 34.0942 31.8395 33.7691 30.4464 33.1264C31.8925 28.3348 34.4995 23.9119 38.0067 20.3108C38.7838 19.5018 40.0157 18.3839 40.8713 17.7114H40.8734L40.8755 17.7082C42.609 16.3206 44.4887 15.1195 46.4681 14.1336C46.9982 15.0918 47.2674 16.2588 47.2674 17.6091C47.2674 18.2741 47.1985 19.099 47.0607 20.0624V20.0667Z"
        fill="white"/>
      <path
        d="M120.672 34.5928L123.745 17.3702C124.884 17.2125 126.038 17.1304 127.207 17.1304C128.777 17.1304 130.324 17.2764 131.839 17.561L133 10.8648C131.122 10.5163 129.186 10.333 127.207 10.333C123.84 10.333 120.594 10.8616 117.55 11.8389L113.505 34.5917H120.672V34.5928Z"
        fill="white"/>
      <path
        d="M53.788 34.5928L56.8615 17.3702C58.0001 17.2125 59.1547 17.1304 60.323 17.1304C61.8932 17.1304 63.44 17.2764 64.955 17.561L66.1159 10.8648C64.2383 10.5163 62.3024 10.333 60.323 10.333C56.9559 10.333 53.7106 10.8616 50.6657 11.8389L46.6211 34.5917H53.788V34.5928Z"
        fill="white"/>
      <path
        d="M53.761 38.2466H55.7637C56.2196 38.2466 56.6087 38.2956 56.9341 38.3926C57.2586 38.4896 57.5204 38.626 57.7208 38.8018C57.9201 38.9777 58.0675 39.1898 58.1608 39.437C58.2541 39.6853 58.3007 39.9593 58.3007 40.2609C58.3007 40.5998 58.2456 40.9056 58.1375 41.1785C58.0283 41.4513 57.8661 41.6836 57.6508 41.8744C57.4356 42.0662 57.1653 42.2144 56.8408 42.321C56.5164 42.4275 56.139 42.4808 55.7086 42.4808H54.4353V44.786H53.761V38.2466ZM54.4353 41.8499H55.699C56.3479 41.8499 56.8218 41.7092 57.1218 41.4268C57.4218 41.1444 57.5713 40.765 57.5713 40.2886C57.5713 39.7866 57.4102 39.4274 57.0889 39.2111C56.7677 38.9947 56.3224 38.886 55.7552 38.886H54.4353V41.8499Z"
        fill="white"/>
      <path
        d="M58.8347 42.5482C58.8347 42.2093 58.8909 41.896 59.0033 41.6072C59.1157 41.3184 59.2736 41.069 59.4761 40.859C59.6786 40.6491 59.9225 40.4839 60.2066 40.3656C60.4907 40.2462 60.8014 40.1865 61.1375 40.1865C61.4735 40.1865 61.7927 40.2462 62.0736 40.3656C62.3546 40.4849 62.5942 40.6491 62.7945 40.859C62.9939 41.069 63.1497 41.3184 63.2621 41.6072C63.3745 41.896 63.4307 42.2093 63.4307 42.5482C63.4307 42.8871 63.3745 43.2005 63.2621 43.4893C63.1497 43.7781 62.9939 44.0286 62.7945 44.2417C62.5952 44.4549 62.3546 44.6211 62.0736 44.7405C61.7927 44.8599 61.481 44.9195 61.1375 44.9195C60.794 44.9195 60.4823 44.8599 60.2013 44.7405C59.9204 44.6211 59.6786 44.4549 59.4761 44.2417C59.2736 44.0286 59.1157 43.777 59.0033 43.4893C58.8909 43.2005 58.8347 42.8871 58.8347 42.5482ZM59.509 42.5482C59.509 42.8115 59.5482 43.0502 59.6256 43.2634C59.7041 43.4765 59.8143 43.6619 59.9575 43.8186C60.1006 43.9753 60.2723 44.0957 60.4727 44.181C60.672 44.2652 60.8936 44.3078 61.1375 44.3078C61.3813 44.3078 61.6135 44.2641 61.816 44.1756C62.0185 44.0882 62.1892 43.9668 62.3259 43.8133C62.4627 43.6598 62.5698 43.4744 62.644 43.258C62.7193 43.0417 62.7564 42.8051 62.7564 42.5472C62.7564 42.2893 62.7203 42.0655 62.6482 41.8555C62.5761 41.6455 62.4722 41.4622 62.3344 41.3045C62.1966 41.1478 62.027 41.0253 61.8245 40.9379C61.622 40.8505 61.3919 40.8057 61.1364 40.8057C60.8809 40.8057 60.6508 40.8484 60.4483 40.9326C60.2458 41.0167 60.0741 41.1361 59.9331 41.2906C59.7931 41.4441 59.6861 41.6274 59.615 41.8406C59.5429 42.0537 59.5069 42.2893 59.5069 42.5461L59.509 42.5482Z"
        fill="white"/>
      <path
        d="M67.9804 42.1231C67.9178 41.9472 67.8606 41.7735 67.8076 41.6009C67.7546 41.4282 67.7122 41.2758 67.6814 41.1447H67.6528C67.6093 41.2886 67.5574 41.4495 67.498 41.6243C67.4386 41.8002 67.3782 41.9664 67.3157 42.1231L66.3053 44.7864H65.6872L64.1807 40.3081H64.8454L65.8282 43.2912C65.8589 43.3977 65.8918 43.515 65.9268 43.6439C65.9607 43.7729 65.9904 43.8869 66.0159 43.9871H66.0445C66.0816 43.8496 66.124 43.7089 66.1706 43.5682C66.2173 43.4276 66.2692 43.2848 66.3254 43.1398L67.4111 40.3081H67.973L69.0491 43.3007C69.0989 43.4265 69.1413 43.5501 69.1752 43.6727C69.2092 43.7952 69.2388 43.8965 69.2643 43.9786H69.2919C69.3226 43.8656 69.3576 43.7484 69.3947 43.6258C69.4318 43.5032 69.4668 43.3924 69.4975 43.2922L70.5832 40.3092H71.2574L69.5823 44.7875H68.9738L67.9815 42.1241L67.9804 42.1231Z"
        fill="white"/>
      <path
        d="M75.7012 44.5273C75.5072 44.6274 75.2803 44.7191 75.0174 44.8001C74.7555 44.8811 74.4374 44.9226 74.0621 44.9226C73.725 44.9226 73.4228 44.8704 73.1546 44.767C72.8864 44.6637 72.6552 44.5145 72.4623 44.3205C72.2693 44.1265 72.1209 43.8857 72.0181 43.6011C71.9152 43.3155 71.8633 42.9915 71.8633 42.627C71.8633 42.2625 71.9163 41.913 72.0223 41.6156C72.1283 41.3183 72.2778 41.0635 72.4718 40.8536C72.6648 40.6436 72.898 40.4816 73.1694 40.3687C73.4408 40.2557 73.7419 40.1992 74.0727 40.1992C74.4035 40.1992 74.7088 40.2557 74.9527 40.3687C75.1965 40.4816 75.3958 40.6372 75.5517 40.8344C75.7075 41.0316 75.822 41.2607 75.8931 41.5218C75.9652 41.7819 76.0012 42.06 76.0012 42.3542C76.0012 42.4171 75.9991 42.481 75.997 42.5471C75.9938 42.6132 75.9896 42.6707 75.9832 42.7208H72.5291C72.5291 42.9403 72.562 43.1471 72.6277 43.3421C72.6934 43.5372 72.7931 43.7077 72.9277 43.8548C73.0624 44.0018 73.2331 44.1201 73.443 44.2075C73.6518 44.2949 73.8999 44.3397 74.1872 44.3397C74.518 44.3397 74.8159 44.2928 75.081 44.199C75.346 44.1052 75.5538 44.0114 75.7033 43.9166V44.5283L75.7012 44.5273ZM74.0261 40.7534C73.6084 40.7534 73.2659 40.8834 73.0009 41.1435C72.7358 41.4035 72.581 41.7595 72.5376 42.2114H75.346C75.346 42.0046 75.3195 41.8128 75.2665 41.6369C75.2135 41.4611 75.134 41.3076 75.028 41.1755C74.9219 41.0433 74.7841 40.9399 74.6166 40.8653C74.448 40.7896 74.2519 40.7523 74.0271 40.7523L74.0261 40.7534Z"
        fill="white"/>
      <path
        d="M79.66 40.8643C79.5911 40.8516 79.4904 40.8366 79.3557 40.8174C79.2211 40.7983 79.0642 40.7897 78.8829 40.7897C78.6836 40.7897 78.4832 40.8068 78.2839 40.842C78.0845 40.8761 77.9245 40.9155 77.8068 40.9592V44.7884H77.1707V40.4977C77.4081 40.4103 77.6668 40.3357 77.9478 40.276C78.2287 40.2164 78.5277 40.1865 78.8468 40.1865C79.0281 40.1865 79.1903 40.1961 79.3334 40.2142C79.4766 40.2334 79.5858 40.2483 79.661 40.2611V40.8633L79.66 40.8643Z"
        fill="white"/>
      <path
        d="M83.8257 44.5273C83.6317 44.6274 83.4048 44.7191 83.1419 44.8001C82.88 44.8811 82.5619 44.9226 82.1866 44.9226C81.8495 44.9226 81.5473 44.8704 81.2791 44.767C81.0109 44.6637 80.7797 44.5145 80.5868 44.3205C80.3938 44.1265 80.2454 43.8857 80.1426 43.6011C80.0397 43.3155 79.9878 42.9915 79.9878 42.627C79.9878 42.2625 80.0408 41.913 80.1468 41.6156C80.2528 41.3183 80.4023 41.0635 80.5963 40.8536C80.7893 40.6436 81.0225 40.4816 81.2939 40.3687C81.5654 40.2557 81.8665 40.1992 82.1972 40.1992C82.528 40.1992 82.8334 40.2557 83.0772 40.3687C83.321 40.4816 83.5203 40.6372 83.6762 40.8344C83.832 41.0316 83.9466 41.2607 84.0176 41.5218C84.0897 41.7819 84.1257 42.06 84.1257 42.3542C84.1257 42.4171 84.1236 42.481 84.1215 42.5471C84.1183 42.6132 84.1141 42.6707 84.1077 42.7208H80.6536C80.6536 42.9403 80.6865 43.1471 80.7522 43.3421C80.8179 43.5372 80.9176 43.7077 81.0522 43.8548C81.1869 44.0018 81.3576 44.1201 81.5675 44.2075C81.7763 44.2949 82.0244 44.3397 82.3117 44.3397C82.6425 44.3397 82.9404 44.2928 83.2055 44.199C83.4705 44.1052 83.6783 44.0114 83.8278 43.9166V44.5283L83.8257 44.5273ZM82.1506 40.7534C81.7329 40.7534 81.3904 40.8834 81.1254 41.1435C80.8603 41.4035 80.7055 41.7595 80.6621 42.2114H83.4705C83.4705 42.0046 83.444 41.8128 83.391 41.6369C83.338 41.4611 83.2585 41.3076 83.1525 41.1755C83.0464 41.0433 82.9086 40.9399 82.7411 40.8653C82.5725 40.7896 82.3764 40.7523 82.1516 40.7523L82.1506 40.7534Z"
        fill="white"/>
      <path
        d="M89.1406 44.4936C88.9285 44.6001 88.673 44.6971 88.373 44.7856C88.074 44.873 87.7178 44.9177 87.3064 44.9177C86.9322 44.9177 86.6014 44.8613 86.3141 44.7483C86.0268 44.6353 85.7851 44.4786 85.5889 44.2783C85.3928 44.0779 85.2423 43.8381 85.1394 43.5589C85.0366 43.2797 84.9846 42.9738 84.9846 42.6413C84.9846 42.2779 85.0387 41.9432 85.1479 41.6395C85.2571 41.3357 85.4129 41.0768 85.6154 40.8636C85.8179 40.6505 86.065 40.4842 86.3544 40.3648C86.6449 40.2455 86.9736 40.1858 87.3414 40.1858C87.6033 40.1858 87.827 40.2092 88.0104 40.2561C88.1949 40.303 88.355 40.352 88.4928 40.4021V37.8433H89.1385V44.4957L89.1406 44.4936ZM87.3531 44.2964C87.5715 44.2964 87.7804 44.2762 87.9807 44.2357C88.18 44.1952 88.3518 44.1461 88.496 44.0897V41.0224C88.3645 40.9723 88.2076 40.9222 88.0231 40.8721C87.8387 40.822 87.6287 40.7965 87.3913 40.7965C86.8548 40.7965 86.4318 40.9563 86.1233 41.2761C85.8148 41.5958 85.66 42.0349 85.66 42.5933C85.66 42.8321 85.6928 43.0527 85.7586 43.2562C85.8243 43.4598 85.925 43.641 86.0628 43.7976C86.1996 43.9543 86.3745 44.0769 86.5866 44.1642C86.7986 44.2527 87.0541 44.2964 87.3542 44.2964H87.3531Z"
        fill="white"/>
      <path
        d="M94.72 44.9188C94.0648 44.9188 93.5029 44.8154 93.0354 44.6087V37.8433H93.6715V40.5716C93.8337 40.4714 94.0277 40.3819 94.2514 40.303C94.4762 40.2242 94.7572 40.1858 95.0943 40.1858C95.4314 40.1858 95.7103 40.2455 95.97 40.3648C96.2287 40.4842 96.4492 40.6473 96.6295 40.854C96.8107 41.0608 96.9475 41.3027 97.0408 41.5787C97.1341 41.8548 97.1808 42.15 97.1808 42.4633C97.1808 42.8395 97.1235 43.1784 97.0079 43.48C96.8924 43.7816 96.7291 44.0385 96.5171 44.2516C96.305 44.4648 96.0463 44.63 95.74 44.7451C95.4346 44.8613 95.0943 44.9188 94.72 44.9188ZM94.9639 40.8071C94.702 40.8071 94.4603 40.8444 94.2387 40.9201C94.0171 40.9958 93.8284 41.0831 93.6726 41.1833V44.1472C93.804 44.1973 93.9631 44.2367 94.1497 44.2644C94.3373 44.2921 94.5207 44.3071 94.702 44.3071C95.2883 44.3071 95.7368 44.144 96.0453 43.8179C96.3538 43.4918 96.5086 43.0463 96.5086 42.4814C96.5086 42.2491 96.4757 42.0317 96.41 41.8271C96.3443 41.6235 96.2478 41.4455 96.1195 41.2952C95.9912 41.145 95.8311 41.0256 95.6371 40.9382C95.4431 40.8508 95.2194 40.8061 94.9628 40.8061L94.9639 40.8071Z"
        fill="white"/>
      <path
        d="M99.2397 46.9989H98.5474L99.5026 44.7971L97.6865 40.3092H98.3979L99.3807 42.8212C99.4676 43.0471 99.5493 43.2602 99.6246 43.4606C99.6998 43.661 99.7709 43.8624 99.8398 44.0628H99.8589C99.9278 43.8741 99.9977 43.6855 100.07 43.4936C100.142 43.3029 100.231 43.0844 100.337 42.8393L101.404 40.3081H102.115L99.2418 46.9978L99.2397 46.9989Z"
        fill="white"/>
      <path
        d="M111.222 44.786L111.212 39.5074H111.193L108.815 44.5228H108.319L105.923 39.5074H105.904V44.786H105.276V38.2466H106.015L108.552 43.5913H108.571L111.136 38.2466H111.847V44.786H111.22H111.222Z"
        fill="white"/>
      <path
        d="M113.151 42.5482C113.151 42.2093 113.207 41.896 113.32 41.6072C113.432 41.3184 113.589 41.069 113.793 40.859C113.995 40.6491 114.239 40.4839 114.523 40.3656C114.807 40.2462 115.118 40.1865 115.454 40.1865C115.79 40.1865 116.109 40.2462 116.39 40.3656C116.671 40.4849 116.911 40.6491 117.111 40.859C117.31 41.069 117.466 41.3184 117.578 41.6072C117.691 41.896 117.747 42.2093 117.747 42.5482C117.747 42.8871 117.691 43.2005 117.578 43.4893C117.466 43.7781 117.31 44.0286 117.111 44.2417C116.912 44.4549 116.671 44.6211 116.39 44.7405C116.109 44.8599 115.797 44.9195 115.454 44.9195C115.11 44.9195 114.799 44.8599 114.518 44.7405C114.237 44.6211 113.995 44.4549 113.793 44.2417C113.59 44.0286 113.432 43.777 113.32 43.4893C113.207 43.2005 113.151 42.8871 113.151 42.5482ZM113.825 42.5482C113.825 42.8115 113.865 43.0502 113.942 43.2634C114.019 43.4765 114.131 43.6619 114.274 43.8186C114.417 43.9753 114.589 44.0957 114.789 44.181C114.988 44.2652 115.21 44.3078 115.454 44.3078C115.698 44.3078 115.93 44.2641 116.132 44.1756C116.335 44.0882 116.506 43.9668 116.642 43.8133C116.779 43.6598 116.886 43.4744 116.96 43.258C117.036 43.0417 117.073 42.8051 117.073 42.5472C117.073 42.2893 117.037 42.0655 116.966 41.8555C116.894 41.6455 116.79 41.4622 116.652 41.3045C116.514 41.1478 116.344 41.0253 116.142 40.9379C115.939 40.8505 115.709 40.8057 115.454 40.8057C115.198 40.8057 114.968 40.8484 114.766 40.9326C114.563 41.0167 114.392 41.1361 114.251 41.2906C114.111 41.4441 114.005 41.6274 113.932 41.8406C113.86 42.0537 113.825 42.2893 113.825 42.5461V42.5482Z"
        fill="white"/>
      <path
        d="M120.668 44.9188C120.013 44.9188 119.451 44.8154 118.984 44.6087V37.8433H119.62V40.5716C119.782 40.4714 119.976 40.3819 120.2 40.303C120.424 40.2242 120.705 40.1858 121.043 40.1858C121.38 40.1858 121.659 40.2455 121.918 40.3648C122.177 40.4842 122.397 40.6473 122.578 40.854C122.759 41.0608 122.896 41.3027 122.989 41.5787C123.082 41.8548 123.129 42.15 123.129 42.4633C123.129 42.8395 123.072 43.1784 122.956 43.48C122.841 43.7816 122.677 44.0385 122.465 44.2516C122.253 44.4648 121.995 44.63 121.688 44.7451C121.383 44.8613 121.043 44.9188 120.668 44.9188ZM120.911 40.8071C120.649 40.8071 120.407 40.8444 120.186 40.9201C119.964 40.9958 119.776 41.0831 119.62 41.1833V44.1472C119.751 44.1973 119.91 44.2367 120.097 44.2644C120.284 44.2921 120.468 44.3071 120.649 44.3071C121.236 44.3071 121.684 44.144 121.992 43.8179C122.301 43.4918 122.456 43.0463 122.456 42.4814C122.456 42.2491 122.423 42.0317 122.357 41.8271C122.291 41.6235 122.195 41.4455 122.067 41.2952C121.938 41.145 121.778 41.0256 121.584 40.9382C121.39 40.8508 121.167 40.8061 120.91 40.8061L120.911 40.8071Z"
        fill="white"/>
      <path d="M124.317 38.4771H125.065V39.2391H124.317V38.4771ZM124.363 40.3112H125.009V44.7895H124.363V40.3112Z"
            fill="white"/>
      <path d="M126.545 37.8433H127.181V44.7877H126.545V37.8433Z" fill="white"/>
  </svg>


);

export default CarkerLogoBW;
