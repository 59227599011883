import React from "react";

const CerfiedCheckShield = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3655_17004)">
      <path
        d="M18.3224 2.36802L10.7376 -0.216797C10.6461 -0.248047 10.5233 -0.263672 10.4005 -0.263672C10.2778 -0.263672 10.155 -0.248047 10.0635 -0.216797L2.47866 2.36802C2.29339 2.43052 2.1416 2.64481 2.1416 2.84124V13.6091C2.1416 13.8055 2.26883 14.0645 2.42285 14.1872L10.117 20.1828C10.1952 20.243 10.2956 20.2743 10.3983 20.2743C10.501 20.2743 10.6037 20.243 10.6795 20.1828L18.3737 14.1872C18.5278 14.0667 18.655 13.8078 18.655 13.6091V2.84124C18.6595 2.64481 18.5077 2.43276 18.3224 2.36802ZM17.0523 13.1805L10.4005 18.3636L3.74874 13.1805V3.63588L10.4005 1.36802L17.0523 3.63588V13.1805ZM7.99428 8.69392C7.92732 8.6024 7.82017 8.5466 7.7041 8.5466H6.47196C6.32687 8.5466 6.24205 8.71177 6.32687 8.83008L9.1483 12.714C9.18166 12.7597 9.22533 12.7968 9.27575 12.8224C9.32617 12.848 9.38192 12.8614 9.43848 12.8614C9.49503 12.8614 9.55078 12.848 9.6012 12.8224C9.65163 12.7968 9.69529 12.7597 9.72866 12.714L14.4742 6.18052C14.559 6.06222 14.4742 5.89704 14.3291 5.89704H13.097C12.9831 5.89704 12.8737 5.95285 12.8068 6.04436L9.43848 10.6828L7.99428 8.69392Z"
        fill="#001450"
      />
    </g>
    <defs>
      <clipPath id="clip0_3655_17004">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.400391)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CerfiedCheckShield;
