import React from "react";

const CaretRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 10"
    fill="none"
  >
    <path
      d="M14.036 1L7.85295 7L1.66992 1"
      stroke="currentColor"
      strokeWidth="2"
      transform="rotate(-90, 7.5 ,4.5)"
    />
  </svg>
);

export default CaretRight;
