import React from "react";

const Chat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M7.99506 30.2399C7.93879 30.2494 7.88133 30.2494 7.82506 30.2399C7.72842 30.2049 7.6449 30.1411 7.58585 30.057C7.5268 29.9729 7.4951 29.8726 7.49506 29.7699V23.6299H4.43506C3.90463 23.6299 3.39592 23.4192 3.02085 23.0441C2.64577 22.669 2.43506 22.1603 2.43506 21.6299V8.87988C2.43506 8.34945 2.64577 7.84074 3.02085 7.46567C3.39592 7.0906 3.90463 6.87988 4.43506 6.87988H8.50506C8.63767 6.87988 8.76484 6.93256 8.85861 7.02633C8.95238 7.1201 9.00506 7.24727 9.00506 7.37988V18.5799C9.00506 18.8451 9.11042 19.0995 9.29795 19.287C9.48549 19.4745 9.73984 19.5799 10.0051 19.5799H19.1651C19.2378 19.5798 19.3096 19.5959 19.3753 19.6271C19.4411 19.6582 19.499 19.7036 19.5451 19.7599L22.1251 22.8099C22.1882 22.8831 22.2292 22.9727 22.2434 23.0683C22.2576 23.1639 22.2443 23.2615 22.2051 23.3499C22.1626 23.436 22.0972 23.5086 22.016 23.5598C21.9348 23.611 21.841 23.6387 21.7451 23.6399H13.8251L8.37506 30.0699C8.32808 30.1244 8.26968 30.1679 8.20401 30.1972C8.13833 30.2266 8.06699 30.2412 7.99506 30.2399ZM4.43506 7.84988C4.16984 7.84988 3.91549 7.95524 3.72795 8.14278C3.54042 8.33031 3.43506 8.58467 3.43506 8.84988V21.5999C3.43506 21.8651 3.54042 22.1195 3.72795 22.307C3.91549 22.4945 4.16984 22.5999 4.43506 22.5999H7.99506C8.06072 22.5999 8.12574 22.6128 8.1864 22.6379C8.24706 22.6631 8.30218 22.6999 8.34861 22.7463C8.39504 22.7928 8.43187 22.8479 8.457 22.9085C8.48213 22.9692 8.49506 23.0342 8.49506 23.0999V28.3499L13.2151 22.7699C13.262 22.7154 13.3204 22.6719 13.3861 22.6425C13.4518 22.6131 13.5231 22.5986 13.5951 22.5999H20.6951L18.9651 20.5399H10.0351C9.50463 20.5399 8.99592 20.3292 8.62085 19.9541C8.24577 19.579 8.03506 19.0703 8.03506 18.5399V7.84988H4.43506Z"
      fill="currentColor"
    />
    <path
      d="M24.7952 27.1903C24.7146 27.1984 24.6334 27.1851 24.5596 27.1517C24.4859 27.1184 24.4223 27.0661 24.3752 27.0003L18.9252 20.5603H10.0352C9.50472 20.5603 8.99602 20.3496 8.62094 19.9745C8.24587 19.5995 8.03516 19.0907 8.03516 18.5603V5.82031C8.03516 5.28988 8.24587 4.78117 8.62094 4.4061C8.99602 4.03103 9.50472 3.82031 10.0352 3.82031H28.3752C28.9056 3.82031 29.4143 4.03103 29.7894 4.4061C30.1644 4.78117 30.3752 5.28988 30.3752 5.82031V18.5503C30.3752 19.0807 30.1644 19.5895 29.7894 19.9645C29.4143 20.3396 28.9056 20.5503 28.3752 20.5503H25.2952V26.6703C25.2951 26.7731 25.2634 26.8733 25.2044 26.9574C25.1453 27.0415 25.0618 27.1054 24.9652 27.1403L24.7952 27.1903ZM10.0352 4.80031C9.76994 4.80031 9.51559 4.90567 9.32805 5.09321C9.14051 5.28074 9.03516 5.5351 9.03516 5.80031V18.5503C9.03516 18.8155 9.14051 19.0699 9.32805 19.2574C9.51559 19.445 9.76994 19.5503 10.0352 19.5503H19.1952C19.2679 19.5502 19.3397 19.5663 19.4054 19.5975C19.4712 19.6286 19.5291 19.674 19.5752 19.7303L24.2952 25.3003V20.0703C24.2952 19.9377 24.3478 19.8105 24.4416 19.7168C24.5354 19.623 24.6625 19.5703 24.7952 19.5703H28.3752C28.6404 19.5703 28.8947 19.465 29.0823 19.2774C29.2698 19.0899 29.3752 18.8355 29.3752 18.5703V5.82031C29.3752 5.5551 29.2698 5.30074 29.0823 5.11321C28.8947 4.92567 28.6404 4.82031 28.3752 4.82031L10.0352 4.80031Z"
      fill="currentColor"
    />
    <path
      d="M14.6152 12.9502C15.1785 12.9502 15.6352 12.4935 15.6352 11.9302C15.6352 11.3668 15.1785 10.9102 14.6152 10.9102C14.0519 10.9102 13.5952 11.3668 13.5952 11.9302C13.5952 12.4935 14.0519 12.9502 14.6152 12.9502Z"
      fill="currentColor"
    />
    <path
      d="M19.1948 12.9502C19.7581 12.9502 20.2148 12.4935 20.2148 11.9302C20.2148 11.3668 19.7581 10.9102 19.1948 10.9102C18.6315 10.9102 18.1748 11.3668 18.1748 11.9302C18.1748 12.4935 18.6315 12.9502 19.1948 12.9502Z"
      fill="currentColor"
    />
    <path
      d="M23.7749 12.9502C24.3382 12.9502 24.7949 12.4935 24.7949 11.9302C24.7949 11.3668 24.3382 10.9102 23.7749 10.9102C23.2116 10.9102 22.7549 11.3668 22.7549 11.9302C22.7549 12.4935 23.2116 12.9502 23.7749 12.9502Z"
      fill="currentColor"
    />
  </svg>
);

export default Chat;
