import React from "react";

const CarKerPro = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0003 17.5097C11.9256 17.5097 11.8499 17.4904 11.7822 17.4518C7.22406 14.864 5.51377 10.8168 4.88225 7.8766C4.20092 4.70687 4.58641 2.17596 4.60334 2.06932C4.6382 1.84791 4.82547 1.6844 5.04561 1.6844C8.51798 1.6844 11.7692 0.0634759 11.8011 0.0472261C11.9266 -0.015742 12.074 -0.015742 12.1995 0.0472261C12.2314 0.0634759 15.4827 1.6844 18.955 1.6844C19.1752 1.6844 19.3624 1.8469 19.3973 2.06932C19.4142 2.17596 19.7997 4.70687 19.1184 7.8766C18.4869 10.8168 16.7766 14.864 12.2185 17.4518C12.1507 17.4904 12.075 17.5097 12.0003 17.5097ZM5.44404 2.59236C5.36734 3.39672 5.26176 5.39444 5.76379 7.71207C6.61146 11.6202 8.70923 14.5837 11.9993 16.5266C15.2894 14.5847 17.3872 11.6202 18.2349 7.71207C18.7379 5.39444 18.6323 3.39672 18.5546 2.59236C15.4896 2.49486 12.7514 1.31471 11.9983 0.963311C11.2453 1.31573 8.50703 2.49587 5.44205 2.59236H5.44404Z"
      fill="currentColor"
    />
    <path
      d="M17.0275 19.9982C15.2863 19.9982 13.9257 18.4473 13.8689 18.3813C13.7195 18.2087 13.7195 17.9497 13.8689 17.777C13.9257 17.711 15.2863 16.1602 17.0275 16.1602C18.7687 16.1602 20.1293 17.711 20.1861 17.777C20.3355 17.9497 20.3355 18.2087 20.1861 18.3813C20.1293 18.4473 18.7687 19.9982 17.0275 19.9982ZM14.8471 18.0787C15.2754 18.4646 16.1061 19.0841 17.0275 19.0841C17.9489 19.0841 18.7786 18.4656 19.2079 18.0787C18.7796 17.6927 17.9489 17.0732 17.0275 17.0732C16.1061 17.0732 15.2764 17.6917 14.8471 18.0787Z"
      fill="currentColor"
    />
    <path
      d="M19.7297 11.6518C19.6142 11.6518 19.5424 11.6477 19.5285 11.6467C19.3034 11.6325 19.1241 11.4487 19.1091 11.2192C19.1042 11.1308 18.9896 9.05387 20.2208 7.79857C21.4519 6.54327 23.4899 6.66006 23.5756 6.66514C23.8007 6.67936 23.98 6.86319 23.995 7.09272C23.9999 7.18107 24.1145 9.25801 22.8833 10.5133C21.8623 11.5543 20.2875 11.6518 19.7297 11.6518ZM23.0935 7.58427C22.5227 7.61982 21.5057 7.7813 20.8543 8.44552C20.2028 9.10973 20.0455 10.1457 20.0096 10.7286C20.5804 10.6931 21.5974 10.5316 22.2488 9.86738C22.9003 9.20316 23.0576 8.16724 23.0935 7.58427Z"
      fill="currentColor"
    />
    <path
      d="M19.3099 16.0097C18.0787 16.0097 17.0109 15.4257 16.9531 15.3932C16.7549 15.2825 16.6682 15.0398 16.7509 14.8255C16.7828 14.7432 17.5408 12.8115 19.1774 12.2052C20.813 11.5979 22.6119 12.582 22.6876 12.6236C22.8858 12.7343 22.9725 12.9771 22.8898 13.1914C22.8579 13.2736 22.0999 15.2053 20.4633 15.8117C20.0798 15.9538 19.6864 16.0087 19.3099 16.0087V16.0097ZM17.7699 14.7686C18.3018 14.9819 19.2909 15.2754 20.1565 14.9545C21.0221 14.6335 21.5949 13.7621 21.8688 13.2493C21.3369 13.036 20.3488 12.7435 19.4832 13.0644C18.6126 13.3874 18.0418 14.2567 17.7699 14.7686Z"
      fill="currentColor"
    />
    <path
      d="M21.0002 6.51613C20.8458 6.51613 20.6974 6.43488 20.6138 6.29167C20.5699 6.2155 19.544 4.41685 20.0828 2.7289C20.6217 1.04094 22.4894 0.201031 22.5681 0.1665C22.7753 0.0750947 23.0163 0.155328 23.1319 0.353373C23.1757 0.429544 24.2017 2.2282 23.6628 3.91615C23.1239 5.6041 21.2562 6.44402 21.1775 6.47855C21.1198 6.50394 21.06 6.51613 21.0002 6.51613ZM22.5482 1.20751C22.0551 1.50305 21.2204 2.1175 20.9355 3.01124C20.6486 3.91006 20.9694 4.90333 21.1975 5.43653C21.6905 5.14098 22.5253 4.52654 22.8101 3.63279C23.097 2.73397 22.7763 1.7407 22.5482 1.20751Z"
      fill="currentColor"
    />
    <path
      d="M6.97282 19.9982C5.23165 19.9982 3.87099 18.4473 3.81421 18.3813C3.6648 18.2087 3.6648 17.9497 3.81421 17.777C3.87099 17.711 5.23165 16.1602 6.97282 16.1602C8.71399 16.1602 10.0746 17.711 10.1314 17.777C10.2808 17.9497 10.2808 18.2087 10.1314 18.3813C10.0746 18.4473 8.71399 19.9982 6.97282 19.9982ZM4.79237 18.0787C5.22069 18.4646 6.05143 19.0841 6.97282 19.0841C7.8942 19.0841 8.72395 18.4656 9.15326 18.0787C8.72494 17.6927 7.8942 17.0732 6.97282 17.0732C6.05143 17.0732 5.22169 17.6917 4.79237 18.0787Z"
      fill="currentColor"
    />
    <path
      d="M4.27051 11.6518C3.7127 11.6518 2.13787 11.5533 1.11688 10.5133C-0.114289 9.25801 -0.000734887 7.18006 0.00524166 7.09272C0.0191869 6.86319 0.199479 6.68038 0.424595 6.66514C0.511255 6.66006 2.54827 6.54327 3.77943 7.79857C5.0106 9.05387 4.89705 11.1318 4.89107 11.2192C4.87713 11.4487 4.69683 11.6315 4.47172 11.6467C4.45677 11.6467 4.38506 11.6518 4.27051 11.6518ZM0.906706 7.58427C0.94157 8.16622 1.09995 9.20316 1.75139 9.86738C2.40283 10.5316 3.41885 10.6921 3.99061 10.7286C3.95574 10.1467 3.79736 9.10973 3.14592 8.44552C2.49447 7.7813 1.47846 7.62084 0.906706 7.58427Z"
      fill="currentColor"
    />
    <path
      d="M4.6912 16.0088C4.31368 16.0088 3.92122 15.954 3.53773 15.8118C1.90115 15.2055 1.14312 13.2727 1.11125 13.1915C1.02857 12.9772 1.11523 12.7335 1.31346 12.6238C1.38916 12.5811 3.1881 11.598 4.82368 12.2053C6.46026 12.8127 7.21829 14.7444 7.25016 14.8256C7.33284 15.0399 7.24618 15.2837 7.04796 15.3933C6.99018 15.4258 5.92237 16.0098 4.6912 16.0098V16.0088ZM2.13125 13.2484C2.40517 13.7613 2.97792 14.6316 3.84353 14.9536C4.70913 15.2745 5.69726 14.982 6.22917 14.7687C5.95624 14.2569 5.38249 13.3855 4.51689 13.0635C3.65128 12.7426 2.66316 13.0351 2.13125 13.2484Z"
      fill="currentColor"
    />
    <path
      d="M3.00028 6.51613C2.94051 6.51613 2.88074 6.50394 2.82297 6.47855C2.74328 6.44402 0.876607 5.6041 0.337722 3.91615C-0.201164 2.2282 0.824812 0.429544 0.86864 0.353373C0.983191 0.155328 1.22524 0.0750947 1.43243 0.1665C1.51212 0.201031 3.37879 1.04094 3.91767 2.7289C4.45656 4.41685 3.43059 6.2155 3.38676 6.29167C3.30408 6.43488 3.15467 6.51613 3.00028 6.51613ZM1.45335 1.20751C1.22524 1.74273 0.9045 2.74007 1.18938 3.63381C1.47626 4.53263 2.30899 5.14403 2.80106 5.43754C3.02916 4.90333 3.3499 3.90498 3.06502 3.01124C2.77815 2.11242 1.94541 1.50102 1.45335 1.20751Z"
      fill="currentColor"
    />
    <path
      d="M9.93718 11.5995C9.84455 11.5995 9.75191 11.57 9.67422 11.5121C9.53576 11.4095 9.46703 11.2369 9.49592 11.0652L9.85052 8.95988L8.35041 7.46895C8.22789 7.34708 8.18407 7.1663 8.23686 7.00075C8.28965 6.83521 8.4301 6.71435 8.59844 6.68998L10.6713 6.38326L11.5987 4.46781C11.6744 4.31141 11.8298 4.21289 12.0011 4.21289C12.1724 4.21289 12.3278 4.31141 12.4035 4.46781L13.3309 6.38326L15.4037 6.68998C15.5721 6.71537 15.7125 6.83521 15.7653 7.00075C15.8181 7.1663 15.7743 7.34809 15.6518 7.46895L14.1516 8.95988L14.5063 11.0652C14.5351 11.2369 14.4664 11.4095 14.328 11.5121C14.1905 11.6147 14.0072 11.6279 13.8558 11.5466L12.0011 10.5524L10.1474 11.5466C10.0816 11.5822 10.0099 11.5995 9.93918 11.5995H9.93718ZM12.0001 9.5794C12.0718 9.5794 12.1435 9.59667 12.2083 9.63221L13.4673 10.3066L13.2273 8.87761C13.2024 8.72933 13.2502 8.578 13.3558 8.4734L14.3738 7.46083L12.9663 7.25263C12.8209 7.2313 12.6944 7.13786 12.6286 7.00279L11.9991 5.7028L11.3696 7.00279C11.3038 7.13786 11.1783 7.2313 11.0319 7.25263L9.62441 7.46083L10.6424 8.4734C10.748 8.578 10.7958 8.72933 10.7709 8.87761L10.5309 10.3066L11.7899 9.63221C11.8557 9.59768 11.9264 9.5794 11.9981 9.5794H12.0001Z"
      fill="currentColor"
    />
  </svg>
);

export default CarKerPro;
