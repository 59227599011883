import React from "react";

const CaretDown = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1.54688L7 7.54688L1 1.54688"
      stroke="currentColor"
      stroke-width="2"
    />
  </svg>
);

export default CaretDown;
