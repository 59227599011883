import React from "react";

const CarkerLogoColor = () => (
  <svg width="142" height="72" viewBox="0 0 142 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5228_29259)">
      <path
        d="M117.447 45.9258C116.506 46.2801 115.404 46.584 114.14 46.8421C112.876 47.099 111.401 47.228 109.716 47.228C107.609 47.228 105.802 46.9459 104.295 46.3839C102.789 45.8219 101.556 45.05 100.601 44.0692C99.644 43.0884 98.9399 41.9163 98.4861 40.5485C98.0313 39.1828 97.8049 37.6958 97.8049 36.0874C97.8049 34.1587 98.0969 32.3666 98.6796 30.7101C99.2635 29.0547 100.122 27.6158 101.257 26.3945C102.391 25.1732 103.792 24.2165 105.462 23.5254C107.13 22.8344 109.067 22.4889 111.272 22.4889C112.925 22.4889 114.375 22.7141 115.624 23.1635C116.872 23.614 117.917 24.2482 118.76 25.0682C119.602 25.8883 120.235 26.8526 120.656 27.9613C121.077 29.07 121.288 30.2848 121.288 31.6023C121.288 32.1807 121.255 32.7438 121.19 33.2905C121.125 33.8372 121.045 34.3511 120.948 34.8333C120.85 35.3155 120.761 35.7343 120.68 36.0874C120.599 36.4417 120.542 36.6986 120.509 36.8594H104.999V37.1972C104.999 38.5476 105.542 39.5765 106.629 40.2839C107.714 40.9913 109.197 41.3445 111.078 41.3445C112.665 41.3445 114.092 41.1925 115.356 40.8863C116.62 40.5813 117.689 40.2522 118.565 39.8979L117.447 45.9258ZM110.835 27.8422C109.441 27.8422 108.298 28.2358 107.407 29.0241C106.515 29.8124 105.908 30.9288 105.584 32.3754H114.336C114.4 32.1829 114.45 31.9894 114.481 31.797C114.513 31.6045 114.53 31.3793 114.53 31.1223C114.53 30.1908 114.23 29.4101 113.63 28.7836C113.029 28.157 112.098 27.8432 110.834 27.8432L110.835 27.8422Z"
        fill="#001450"/>
      <path
        d="M29.225 37.8325C28.3427 38.2415 27.2985 38.6646 26.0892 39.1009C24.507 39.6738 22.5936 39.9592 20.3521 39.9592C19.0991 39.9592 17.9204 39.7875 16.8161 39.4442C15.7107 39.1009 14.7463 38.5607 13.923 37.8249C13.0986 37.089 12.4469 36.1498 11.9691 35.0039C11.4913 33.8591 11.2518 32.4858 11.2518 30.8829C11.2518 29.0843 11.4989 27.466 11.9942 26.026C12.4884 24.5871 13.2221 23.3527 14.1952 22.3227C15.1673 21.2927 16.3711 20.5 17.8056 19.9435C19.2402 19.3881 20.8791 19.1092 22.727 19.1092C23.6826 19.1092 24.6229 19.1825 25.5458 19.3301C26.4686 19.4777 27.3335 19.6658 28.1426 19.8943C28.9506 20.1239 29.6832 20.3688 30.3436 20.6301C31.0029 20.8926 31.7005 21.1047 32.1302 21.3004L33.3821 14.1737C32.8212 13.9451 31.9924 13.6401 31.3331 13.3941C30.6727 13.1491 29.8986 12.9206 29.0085 12.7074C28.1185 12.4953 27.0874 12.3236 25.9175 12.1924C24.7465 12.0623 23.371 11.9967 21.7878 11.9967C19.051 11.9967 16.5373 12.4384 14.2455 13.3208C11.9538 14.2043 9.98347 15.4628 8.33573 17.0985C6.68689 18.7342 5.39341 20.7373 4.45309 23.1078C3.51386 25.4794 3.0437 28.1527 3.0437 31.1278C3.0437 33.7115 3.43076 36.0087 4.20598 38.0195C4.9801 40.0302 6.07678 41.7228 7.49491 43.0961C8.91195 44.4694 10.6023 45.5082 12.5639 46.2112C14.5254 46.9143 16.6936 47.2663 19.0674 47.2663C20.9469 47.2663 22.7434 47.0706 24.4578 46.6781C25.7229 46.3883 26.8524 46.0548 27.8484 45.6765L29.2261 37.8314L29.225 37.8325Z"
        fill="#FE000C"/>
      <path
        d="M58.6701 47.251L61.7688 29.6977C62.9453 29.537 64.1382 29.4539 65.3442 29.4539C66.9657 29.4539 68.5632 29.6026 70.1289 29.8934L71.3273 23.0685C69.3876 22.7131 67.3878 22.5272 65.3442 22.5272C61.8661 22.5272 58.5138 23.0652 55.3692 24.0623L51.2744 47.2521H58.6712L58.6701 47.251Z"
        fill="#FE000C"/>
      <path
        d="M121.098 42.942L120.321 47.2511H127.725L130.899 29.6977C132.075 29.537 133.268 29.4539 134.474 29.4539C136.096 29.4539 137.693 29.6026 139.259 29.8934L140.457 23.0685C138.518 22.7131 136.518 22.5272 134.474 22.5272C130.996 22.5272 127.644 23.0652 124.499 24.0623L121.098 42.942Z"
        fill="#001450"/>
      <path
        d="M80.2198 26.0654L82.716 11.965H74.8337L68.5894 47.2499H76.4727L78.9515 33.3015C77.8121 32.5974 76.6182 31.9807 75.3706 31.4581C74.1471 30.9442 72.8919 30.5298 71.6137 30.2127L72.8142 23.3746C75.4154 23.9771 77.8974 24.8878 80.2198 26.0643V26.0654Z"
        fill="#001450"/>
      <path
        d="M103.364 11.9661H93.8587L81.7461 26.8888C83.7437 28.0368 85.6047 29.3905 87.306 30.9168H87.3104L103.364 11.9661Z"
        fill="#001450"/>
      <path
        d="M83.5624 36.9392C85.9307 39.2889 87.7894 42.0224 89.0873 45.0642C89.3913 45.776 89.6591 46.4988 89.8942 47.2303H97.1467C94.2197 35.4172 84.7771 26.1452 72.8143 23.3757L71.6138 30.2138C72.8919 30.5308 74.1461 30.9452 75.3707 31.4591C78.4376 32.7461 81.1941 34.5895 83.5624 36.9392Z"
        fill="#FE000C"/>
      <path
        d="M41.5268 28.5201C42.9198 28.5201 43.9159 28.7366 44.515 29.1706C44.6878 29.2953 44.8343 29.4352 44.9568 29.5872C46.8997 28.0521 49.022 26.7324 51.2886 25.6619C50.9901 25.2246 50.6369 24.8266 50.2247 24.4712C48.7344 23.1865 46.435 22.5436 43.3243 22.5436C41.6722 22.5436 40.101 22.6803 38.6107 22.9536C37.1204 23.227 35.6924 23.5834 34.8833 23.8403L33.7659 29.8168C34.6406 29.5599 36.003 29.2034 37.2986 28.9301C38.5943 28.6578 40.0026 28.5201 41.5257 28.5201H41.5268Z"
        fill="#001450"/>
      <path
        d="M43.7605 41.0438L43.3866 41.6266C42.7065 42.7221 42.1095 43.8691 41.5989 45.0642C41.2905 45.7869 41.0183 46.5217 40.781 47.2641C42.5643 47.2433 44.2307 47.0935 45.7778 46.8103C47.4463 46.5053 48.847 46.2079 49.9808 45.9192L51.6811 35.9912C51.8669 34.9066 52.0266 33.9269 52.1589 33.0489C50.334 34.1281 48.648 35.427 47.1238 36.9392C45.8522 38.1999 44.7293 39.5732 43.7594 41.0438H43.7605Z"
        fill="#001450"/>
      <path
        d="M33.8664 46.0188C35.3851 40.777 38.1984 36.0798 41.9411 32.2879C41.6623 32.2825 41.3802 32.2792 41.0894 32.2792C39.1453 32.2792 37.5009 32.464 36.1571 32.8335C34.8122 33.2031 33.7275 33.7246 32.9009 34.4004C32.0743 35.075 31.4752 35.8786 31.1034 36.8102C30.7306 37.7429 30.5447 38.786 30.5447 39.9428C30.5447 42.3209 31.3297 44.1359 32.9009 45.389C33.1907 45.6208 33.5132 45.8307 33.8653 46.0188H33.8664Z"
        fill="#001450"/>
      <path
        d="M43.3866 41.6255C44.4374 39.9329 45.6871 38.365 47.125 36.9381C48.6491 35.427 50.3352 34.127 52.16 33.0478C52.1885 32.8597 52.2158 32.6739 52.242 32.4956C52.3875 31.4832 52.4607 30.6237 52.4607 29.9174C52.4607 28.2216 52.0682 26.8045 51.2897 25.6619C49.0231 26.7313 46.9008 28.0521 44.9578 29.5872C44.0011 30.3296 42.7109 31.4974 41.9412 32.2868C38.1985 36.0787 35.3852 40.777 33.8665 46.0176C35.4234 46.8508 37.5873 47.2674 40.3612 47.2674C40.5034 47.2674 40.6422 47.2652 40.7822 47.263C41.0205 46.5195 41.2917 45.7858 41.6 45.0631C42.1096 43.868 42.7065 42.72 43.3855 41.6244"
        fill="#FE000C"/>
      <path d="M132.07 45.2709H131.315V44.874H133.259V45.2709H132.502V47.3133H132.07V45.2709Z" fill="#001450"/>
      <path
        d="M135.745 47.3133L135.741 45.5726H135.735L135.015 47.2783H134.679L133.956 45.5759H133.949V47.3133H133.542V44.874H134.076L134.845 46.689H134.851L135.631 44.874H136.154V47.3133H135.747H135.745Z"
        fill="#001450"/>
      <path
        d="M59.5833 51.1326H61.6192C62.0817 51.1326 62.4786 51.1818 62.8088 51.2802C63.139 51.3786 63.4047 51.5164 63.608 51.6935C63.8114 51.8706 63.9601 52.0849 64.0552 52.3353C64.1504 52.5857 64.1985 52.8634 64.1985 53.1674C64.1985 53.5096 64.1427 53.819 64.0323 54.0946C63.9207 54.3701 63.7567 54.6052 63.5381 54.7987C63.3194 54.9922 63.0449 55.1431 62.7147 55.2503C62.3845 55.3585 62.0018 55.4121 61.5634 55.4121H60.2699V57.7432H59.5854V51.1326H59.5833ZM60.2677 54.7758H61.5525C62.2118 54.7758 62.694 54.6325 62.9979 54.3471C63.3019 54.0618 63.455 53.678 63.455 53.1958C63.455 52.6885 63.292 52.3255 62.9651 52.1068C62.6382 51.8881 62.1866 51.7788 61.6093 51.7788H60.2677V54.7758Z"
        fill="#001450"/>
      <path
        d="M64.7385 55.4799C64.7385 55.1377 64.7954 54.8206 64.9102 54.5286C65.0239 54.2367 65.1846 53.9852 65.3902 53.772C65.5957 53.5599 65.8439 53.3937 66.1326 53.2723C66.4213 53.1521 66.7362 53.0919 67.0795 53.0919C67.4228 53.0919 67.7454 53.1521 68.0307 53.2723C68.3161 53.3926 68.5599 53.5588 68.7633 53.772C68.9667 53.9841 69.1252 54.2367 69.2389 54.5286C69.3526 54.8206 69.4106 55.1377 69.4106 55.4799C69.4106 55.8221 69.3537 56.1392 69.2389 56.4311C69.1252 56.7231 68.9667 56.9767 68.7633 57.1921C68.5599 57.4075 68.3161 57.5759 68.0307 57.6962C67.7454 57.8165 67.4283 57.8766 67.0795 57.8766C66.7307 57.8766 66.4136 57.8165 66.1282 57.6962C65.8428 57.5759 65.5968 57.4075 65.3913 57.1921C65.1846 56.9767 65.025 56.7231 64.9113 56.4311C64.7976 56.1392 64.7396 55.8221 64.7396 55.4799H64.7385ZM65.423 55.4799C65.423 55.7467 65.4624 55.9872 65.5422 56.2026C65.6209 56.418 65.7335 56.605 65.88 56.7635C66.0255 56.9221 66.2004 57.0445 66.4038 57.1298C66.6071 57.2151 66.8324 57.2577 67.0795 57.2577C67.3266 57.2577 67.5628 57.2129 67.7694 57.1244C67.975 57.0358 68.1488 56.9133 68.2877 56.7581C68.4265 56.6028 68.5348 56.4158 68.6113 56.1972C68.6879 55.9785 68.725 55.739 68.725 55.4788C68.725 55.2186 68.689 54.9922 68.6157 54.7801C68.5424 54.568 68.4364 54.3821 68.2975 54.2236C68.1576 54.065 67.9848 53.9415 67.7793 53.8529C67.5726 53.7644 67.3397 53.7195 67.0806 53.7195C66.8214 53.7195 66.5875 53.7622 66.3819 53.8475C66.1752 53.9327 66.0014 54.0541 65.8582 54.2094C65.7149 54.3646 65.6078 54.5505 65.5345 54.7659C65.4613 54.9813 65.4252 55.2197 65.4252 55.4799H65.423Z"
        fill="#001450"/>
      <path
        d="M74.0324 55.0513C73.969 54.8741 73.9099 54.6981 73.8564 54.5231C73.8028 54.3493 73.7601 54.1951 73.7284 54.0617H73.7C73.6552 54.2072 73.6038 54.369 73.5426 54.5472C73.4824 54.7243 73.4201 54.8927 73.3567 55.0513L72.3289 57.7432H71.7013L70.1694 53.2155H70.8452L71.8445 56.231C71.8762 56.3393 71.909 56.4574 71.944 56.5875C71.979 56.7176 72.0085 56.8335 72.0348 56.9352H72.0632C72.1015 56.7952 72.1441 56.6542 72.1911 56.512C72.2381 56.3699 72.2906 56.2256 72.3486 56.0791L73.4518 53.2155H74.0226L75.1159 56.2409C75.1662 56.3677 75.21 56.4934 75.2439 56.617C75.2778 56.7406 75.3095 56.8433 75.3346 56.9264H75.3631C75.3948 56.8127 75.4298 56.6935 75.468 56.57C75.5063 56.4464 75.5413 56.3338 75.573 56.2321L76.6762 53.2165H77.3607L75.6583 57.7443H75.0405L74.0324 55.0524V55.0513Z"
        fill="#001450"/>
      <path
        d="M81.8808 57.4775C81.684 57.5792 81.4522 57.671 81.1865 57.753C80.9198 57.835 80.5972 57.8766 80.2167 57.8766C79.8745 57.8766 79.5661 57.8241 79.2939 57.7191C79.0216 57.6142 78.7865 57.4644 78.5897 57.2676C78.3929 57.0708 78.2431 56.828 78.1382 56.5394C78.0332 56.2507 77.9807 55.9227 77.9807 55.5553C77.9807 55.1879 78.0343 54.8337 78.1425 54.533C78.2508 54.2323 78.4028 53.9754 78.5996 53.7621C78.7964 53.55 79.0326 53.386 79.3081 53.2723C79.5836 53.1586 79.8898 53.1006 80.2265 53.1006C80.5633 53.1006 80.8738 53.1575 81.1209 53.2723C81.368 53.3871 81.5714 53.5435 81.73 53.7436C81.8885 53.9437 82.0044 54.1755 82.0777 54.4379C82.1509 54.7014 82.187 54.9813 82.187 55.2798C82.187 55.3432 82.1848 55.4077 82.1826 55.4744C82.1793 55.5411 82.175 55.6001 82.1684 55.6504H78.6586C78.6586 55.8724 78.6914 56.0812 78.7581 56.2781C78.8248 56.4749 78.9265 56.6476 79.0621 56.7963C79.1988 56.945 79.3726 57.0642 79.5858 57.1528C79.7979 57.2413 80.0505 57.2862 80.3424 57.2862C80.6781 57.2862 80.981 57.238 81.2511 57.1429C81.52 57.0478 81.7311 56.9527 81.8841 56.8575V57.4764L81.8808 57.4775ZM80.1784 53.6626C79.7531 53.6626 79.4065 53.795 79.1364 54.0574C78.8664 54.3209 78.71 54.6806 78.6652 55.1376H81.5189C81.5189 54.9288 81.4916 54.7353 81.438 54.557C81.3844 54.3799 81.3035 54.2236 81.1953 54.0913C81.087 53.9579 80.9482 53.854 80.7765 53.7775C80.6049 53.7009 80.4059 53.6637 80.1773 53.6637L80.1784 53.6626Z"
        fill="#001450"/>
      <path
        d="M85.9045 53.7775C85.8345 53.7644 85.7317 53.7491 85.5951 53.7305C85.4584 53.7119 85.2988 53.702 85.1151 53.702C84.9117 53.702 84.7094 53.7195 84.506 53.7545C84.3027 53.7895 84.1409 53.8289 84.0206 53.8737V57.7454H83.3733V53.4079C83.6138 53.3194 83.8773 53.245 84.1627 53.1849C84.4481 53.1247 84.7521 53.0941 85.0757 53.0941C85.2594 53.0941 85.4245 53.104 85.5699 53.1225C85.7153 53.1411 85.8269 53.1575 85.9023 53.1706V53.7797L85.9045 53.7775Z"
        fill="#001450"/>
      <path
        d="M90.1369 57.4775C89.9401 57.5792 89.7083 57.671 89.4426 57.753C89.1759 57.835 88.8533 57.8766 88.4728 57.8766C88.1306 57.8766 87.8222 57.8241 87.55 57.7191C87.2777 57.6142 87.0426 57.4644 86.8458 57.2676C86.649 57.0708 86.4992 56.828 86.3943 56.5394C86.2893 56.2507 86.2368 55.9227 86.2368 55.5553C86.2368 55.1879 86.2904 54.8337 86.3986 54.533C86.5069 54.2323 86.6589 53.9754 86.8557 53.7621C87.0525 53.55 87.2887 53.386 87.5642 53.2723C87.8397 53.1586 88.1459 53.1006 88.4827 53.1006C88.8194 53.1006 89.1299 53.1575 89.377 53.2723C89.6241 53.3871 89.8275 53.5435 89.9861 53.7436C90.1446 53.9437 90.2605 54.1755 90.3338 54.4379C90.407 54.7014 90.4431 54.9813 90.4431 55.2798C90.4431 55.3432 90.4409 55.4077 90.4387 55.4744C90.4354 55.5411 90.4311 55.6001 90.4245 55.6504H86.9147C86.9147 55.8724 86.9475 56.0812 87.0142 56.2781C87.0809 56.4749 87.1826 56.6476 87.3182 56.7963C87.4549 56.945 87.6287 57.0642 87.8419 57.1528C88.054 57.2413 88.3066 57.2862 88.5985 57.2862C88.9342 57.2862 89.2371 57.238 89.5072 57.1429C89.7761 57.0478 89.9872 56.9527 90.1402 56.8575V57.4764L90.1369 57.4775ZM88.4345 53.6626C88.0092 53.6626 87.6626 53.795 87.3925 54.0574C87.1225 54.3209 86.9661 54.6806 86.9213 55.1376H89.775C89.775 54.9288 89.7477 54.7353 89.6941 54.557C89.6406 54.3799 89.5596 54.2236 89.4514 54.0913C89.3432 53.9579 89.2043 53.854 89.0326 53.7775C88.861 53.7009 88.662 53.6637 88.4334 53.6637L88.4345 53.6626Z"
        fill="#001450"/>
      <path
        d="M95.5407 57.4491C95.3253 57.5573 95.0651 57.6558 94.7611 57.7443C94.4571 57.8329 94.0952 57.8777 93.6764 57.8777C93.2959 57.8777 92.9603 57.8209 92.6683 57.7061C92.3764 57.5923 92.1304 57.4338 91.9314 57.2304C91.7313 57.0271 91.5793 56.7854 91.4744 56.5022C91.3694 56.2201 91.3169 55.9107 91.3169 55.575C91.3169 55.2076 91.3727 54.8698 91.4831 54.5615C91.5935 54.2542 91.7521 53.9918 91.9587 53.7764C92.1643 53.561 92.4158 53.3926 92.7099 53.2723C93.0051 53.1521 93.3397 53.0919 93.7136 53.0919C93.9804 53.0919 94.2067 53.116 94.3937 53.163C94.5807 53.2111 94.7436 53.2592 94.8835 53.3106V50.7236H95.5396V57.4491H95.5407ZM93.7235 57.249C93.9454 57.249 94.1575 57.2282 94.3609 57.1867C94.5643 57.1451 94.7381 57.0959 94.8846 57.0391V53.9382C94.7512 53.8879 94.5916 53.8365 94.4046 53.7862C94.2177 53.7359 94.0034 53.7097 93.7628 53.7097C93.2172 53.7097 92.7875 53.8715 92.4737 54.1952C92.1599 54.5188 92.0025 54.9627 92.0025 55.5269C92.0025 55.7686 92.0353 55.9916 92.102 56.1972C92.1687 56.4038 92.2714 56.5853 92.4114 56.7439C92.5502 56.9024 92.7285 57.026 92.9439 57.1145C93.1593 57.2031 93.4195 57.2479 93.7235 57.2479V57.249Z"
        fill="#001450"/>
      <path
        d="M101.21 57.8766C100.544 57.8766 99.9732 57.7717 99.4976 57.5628V50.7236H100.145V53.4823C100.31 53.3806 100.507 53.2909 100.734 53.2111C100.963 53.1324 101.248 53.0919 101.59 53.0919C101.933 53.0919 102.217 53.1521 102.479 53.2723C102.743 53.3926 102.966 53.5577 103.149 53.7666C103.333 53.9754 103.473 54.2203 103.568 54.4991C103.663 54.7779 103.71 55.0764 103.71 55.3935C103.71 55.774 103.651 56.1163 103.534 56.4213C103.417 56.7264 103.25 56.9855 103.035 57.2009C102.819 57.4163 102.556 57.5836 102.245 57.7006C101.935 57.8176 101.589 57.8766 101.209 57.8766H101.21ZM101.457 53.7195C101.19 53.7195 100.945 53.7578 100.72 53.8332C100.495 53.9098 100.303 53.9983 100.145 54.1V57.097C100.278 57.1473 100.44 57.1878 100.63 57.2162C100.821 57.2446 101.008 57.2589 101.191 57.2589C101.787 57.2589 102.242 57.0937 102.556 56.7646C102.87 56.4344 103.027 55.985 103.027 55.4143C103.027 55.1803 102.994 54.9594 102.928 54.7528C102.861 54.5472 102.762 54.3679 102.632 54.2148C102.502 54.0629 102.339 53.9426 102.142 53.8529C101.946 53.7644 101.717 53.7195 101.458 53.7195H101.457Z"
        fill="#001450"/>
      <path
        d="M105.804 59.9792H105.1L106.07 57.753L104.224 53.2155H104.947L105.945 55.7554C106.034 55.9839 106.117 56.1993 106.192 56.4027C106.269 56.6061 106.341 56.8083 106.411 57.0117H106.43C106.5 56.8215 106.571 56.6301 106.644 56.4366C106.717 56.2431 106.807 56.0233 106.915 55.7751L108 53.2165H108.723L105.802 59.9803L105.804 59.9792Z"
        fill="#001450"/>
      <path
        d="M117.98 57.7443L117.97 52.4075H117.952L115.536 57.4775H115.031L112.596 52.4075H112.578V57.7443H111.94V51.1337H112.692L115.27 56.5372H115.288L117.895 51.1337H118.618V57.7443H117.98Z"
        fill="#001450"/>
      <path
        d="M119.94 55.4799C119.94 55.1377 119.997 54.8206 120.111 54.5286C120.225 54.2367 120.386 53.9852 120.591 53.772C120.797 53.5599 121.045 53.3937 121.334 53.2723C121.622 53.1521 121.937 53.0919 122.281 53.0919C122.624 53.0919 122.947 53.1521 123.232 53.2723C123.517 53.3926 123.761 53.5588 123.964 53.772C124.168 53.9841 124.326 54.2367 124.44 54.5286C124.554 54.8206 124.612 55.1377 124.612 55.4799C124.612 55.8221 124.555 56.1392 124.44 56.4311C124.326 56.7231 124.168 56.9767 123.964 57.1921C123.761 57.4075 123.517 57.5759 123.232 57.6962C122.947 57.8165 122.629 57.8766 122.281 57.8766C121.932 57.8766 121.615 57.8165 121.329 57.6962C121.044 57.5759 120.798 57.4075 120.592 57.1921C120.386 56.9767 120.226 56.7231 120.112 56.4311C119.999 56.1392 119.941 55.8221 119.941 55.4799H119.94ZM120.624 55.4799C120.624 55.7467 120.664 55.9872 120.743 56.2026C120.822 56.418 120.935 56.605 121.081 56.7635C121.227 56.9221 121.402 57.0445 121.605 57.1298C121.808 57.2151 122.034 57.2577 122.281 57.2577C122.528 57.2577 122.764 57.2129 122.971 57.1244C123.176 57.0358 123.349 56.9133 123.489 56.7581C123.629 56.6028 123.736 56.4158 123.813 56.1972C123.889 55.9785 123.926 55.739 123.926 55.4788C123.926 55.2186 123.89 54.9922 123.817 54.7801C123.744 54.568 123.638 54.3821 123.499 54.2236C123.359 54.065 123.186 53.9415 122.98 53.8529C122.775 53.7644 122.541 53.7195 122.282 53.7195C122.023 53.7195 121.789 53.7622 121.583 53.8475C121.378 53.9327 121.203 54.0541 121.059 54.2094C120.917 54.3646 120.809 54.5505 120.736 54.7659C120.662 54.9813 120.626 55.2197 120.626 55.4799H120.624Z"
        fill="#001450"/>
      <path
        d="M127.578 57.8766C126.912 57.8766 126.342 57.7717 125.866 57.5628V50.7236H126.513V53.4823C126.678 53.3806 126.875 53.2909 127.103 53.2111C127.331 53.1324 127.616 53.0919 127.959 53.0919C128.301 53.0919 128.585 53.1521 128.848 53.2723C129.111 53.3926 129.334 53.5577 129.518 53.7666C129.702 53.9754 129.842 54.2203 129.937 54.4991C130.032 54.7779 130.079 55.0764 130.079 55.3935C130.079 55.774 130.02 56.1163 129.903 56.4213C129.786 56.7264 129.619 56.9855 129.403 57.2009C129.188 57.4163 128.924 57.5836 128.614 57.7006C128.303 57.8176 127.958 57.8766 127.577 57.8766H127.578ZM127.825 53.7195C127.559 53.7195 127.314 53.7578 127.088 53.8332C126.863 53.9098 126.672 53.9983 126.513 54.1V57.097C126.647 57.1473 126.808 57.1878 126.999 57.2162C127.189 57.2446 127.376 57.2589 127.56 57.2589C128.156 57.2589 128.61 57.0937 128.924 56.7646C129.238 56.4344 129.395 55.985 129.395 55.4143C129.395 55.1803 129.363 54.9594 129.296 54.7528C129.229 54.5472 129.131 54.3679 129.001 54.2148C128.871 54.0629 128.708 53.9426 128.511 53.8529C128.314 53.7644 128.086 53.7195 127.826 53.7195H127.825Z"
        fill="#001450"/>
      <path d="M131.288 51.3611H132.049V52.1319H131.288V51.3611ZM131.335 53.2155H131.991V57.7432H131.335V53.2155Z"
            fill="#001450"/>
      <path d="M133.552 50.7236H134.2V57.7443H133.552V50.7236Z" fill="#001450"/>
    </g>
    <defs>
      <clipPath id="clip0_5228_29259">
        <rect width="136.565" height="48" fill="white" transform="translate(3 12)"/>
      </clipPath>
    </defs>
  </svg>


);

export default CarkerLogoColor;
