import React from "react";

const CaretLeftLarge = () => (
  <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4 18.9994C11.4059 18.7492 11.5134 18.5109 11.7 18.3344L21.88 8.64438C22.0703 8.46911 22.325 8.37109 22.59 8.37109C22.8551 8.37109 23.1097 8.46911 23.3 8.64438C23.3938 8.7327 23.4682 8.83777 23.5189 8.95354C23.5697 9.0693 23.5958 9.19347 23.5958 9.31888C23.5958 9.4443 23.5697 9.56847 23.5189 9.68423C23.4682 9.8 23.3938 9.90507 23.3 9.99338L13.82 18.9994L23.3 28.0054C23.3938 28.0937 23.4681 28.1988 23.5189 28.3145C23.5697 28.4303 23.5958 28.5545 23.5958 28.6799C23.5958 28.8053 23.5697 28.9295 23.5189 29.0452C23.4681 29.161 23.3938 29.2661 23.3 29.3544C23.1097 29.5297 22.8551 29.6277 22.59 29.6277C22.325 29.6277 22.0703 29.5297 21.88 29.3544L11.7 19.6644C11.5134 19.4878 11.4059 19.2495 11.4 18.9994Z"
      fill="#6D6E71"/>
  </svg>
);

export default CaretLeftLarge;
