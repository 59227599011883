import React from "react";

const CaretDown = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 8L7 2L13 8" stroke="currentColor" stroke-width="2" />
  </svg>
);

export default CaretDown;
