import React from "react";

const CaretFilledDown = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 0L7 10L2.38419e-07 -6.11959e-07L14 0Z"
      fill="currentColor"
    />
  </svg>
);

export default CaretFilledDown;
